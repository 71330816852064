<template>
  <div v-if="accounts" class="d-flex align-items-center justify-content-between account-card-content">
    <div style="width: 40px;">
      <button class="btn btn-link" v-if="currentIndex > 0" @click="previousAccount">
        <img src="@/assets/images/arrow-left-silver.png" alt="Voltar" />
      </button>
    </div>

    <div class="d-flex flex-column align-items-center">
      <button class="btn btn-link" @click="open" v-if="account">
        <img :src="account.picture" alt="Foto de perfil" class="picture-profile circle-green" />
      </button>
  
      <img src="@/assets/images/logo-purple.png" alt="MeuStory.TV" width="135" v-else />

      <span class="fw-bold fs-30 my-4" v-if="account">@{{ account.name }}</span>

      <button class="button button-primary-outline" v-if="account" @click="openConfig">
        <img src="@/assets/images/config.png" class="icon-xs" alt="Configure sua visualização" />
        <span class="fw-bold fs-15 ms-2">Configure sua visualização</span>
      </button>
      
      <button class="btn btn-remove-account" v-if="account" @click="deleteAccount">
        <img src="@/assets/images/remove.png" class="icon-xs" alt="Remover conta" />
        <span class="fw-bold fs-12">Remover conta</span>
      </button>
      <button class="button button-primary my-5" @click="addAccount" v-if="!account">
        <img src="@/assets/images/add-circle.png" class="icon-xs" alt="Adicionar conta" />
        <span class="fw-bold fs-15 ms-2">Adicionar conta</span>
      </button>
    </div>
    
    <div style="width: 40px;">
      <button class="btn btn-link" v-if="currentIndex < accounts.length" @click="nextAccount">
        <img src="@/assets/images/arrow-right-silver.png" alt="Próximo" />
      </button>
    </div>
    <MessageBox
      :visible="messageBox.visible"
      @close="messageBox.visible = false"
      @confirm="goToWhats"
      :message="messageBox.message"
      :buttonConfirmText="messageBox.buttonConfirm"
      :buttonCloseText="messageBox.buttonClose"
    />
  </div>
</template>

<script>
import MessageBox from '@/components/MessageBox.vue'
import api from '@/services/api';
import { mapState } from 'vuex'

export default {
  name: 'AccountCard',
  components: {
    MessageBox
  },
  data() {
    return {
      name: '',
      currentIndex: 0,
      messageBox: {
        visible: false,
        message: ''
      }
    }
  },
  props: {
    accounts: {
      type: Object,
      required: true
    },
    currentAccount: {
      type: Number,
      default: 0
    }
  },
  created() {
    this.currentIndex = this.currentAccount
  },
  computed: {
    account() {
      return this.accounts[this.currentIndex]
    },
    ...mapState('auth', ['currentUser'])
  },
  methods: {
    addAccount() {
      const client_id = process.env.VUE_APP_FACEBOOK_CLIENT_ID
      const redirect_uri = process.env.VUE_APP_FACEBOOK_REDIRECT_URI
      const scope = process.env.VUE_APP_FACEBOOK_SCOPE_PERMISSIONS
      const state = this.currentUser.id
      const url = `https://www.facebook.com/v20.0/dialog/oauth?client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}&response_type=code&state=${state}`
      
      window
        .open(url, '_self')
        .focus()
    },
    createAccount() {
      this.$emit('create-account', this.name)
    },
    previousAccount() {
      this.currentIndex--
    },
    nextAccount() {
      this.currentIndex++
    },
    async open() {
      const { data:user } = await api.users.getById(this.currentUser.id)

      if (user.plan_expiration_date === null) {
        this.messageBox.visible = true
        this.messageBox.message = 'Estamos animados para ver seu story no <strong>MeuStory.TV</strong><br /><br />Antes, converse com nosso time para liberar seu acesso de teste de 3 dias'
        this.messageBox.buttonConfirm = 'Conversar com o time'
        this.messageBox.buttonClose = 'Fechar'
        return
      }

      if (new Date(user.plan_expiration_date) < new Date()) {
        this.messageBox.visible = true
        this.messageBox.message = 'Seu plano expirou!<br /><br />Converse com nosso time para renovar seu acesso'
        this.messageBox.buttonConfirm = 'Conversar com o time'
        this.messageBox.buttonClose = 'Fechar'
        return
      }

      this.$router.push({
        path: `/accounts/${this.account.id}/show`,
        query: {
          index: this.$route.query.index || 0
        }
      })
    },
    openConfig() {
      this.$router.push(`/accounts/${this.account.id}/config`)
    },
    deleteAccount() {
      this.$emit('delete-account', this.account.id)
    },
    goToWhats() {
      window.open('https://wa.me/5551999153493?text=Quero%20come%C3%A7ar%20usar%20o%20MeuStory.TV', '_blank')
    }
  }
}
</script>

<style scoped>
.account-card-content {
  height: 400px;
  min-height: 400px;
  width: 95%;
}

.btn-remove-account {
  color: #5A5858;
  margin-top: 60px;
}

.btn-remove-account > span {
  margin-left: 5px;
}

.picture-profile {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

@media screen and (max-width: 768px) {
  .picture-profile {
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }
}

</style>