import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { createHead } from '@vueuse/head'
import store from './store'
import VueQrcode from '@chenfengyuan/vue-qrcode'

const head = createHead()

createApp(App)
  .use(head)
  .use(router)
  .use(store)
  .component(VueQrcode.name, VueQrcode)
  .mount('#app')
