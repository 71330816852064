function setCookie(name, value, days) {
  const date = new Date()
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
  const expires = "expires=" + date.toUTCString()
  document.cookie = name + "=" + value + ";" + expires + ";path=/"
}

function getCookie(name) {
  const nameEQ = name + "="
  const cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i].trim()
    if (cookie.indexOf(nameEQ) === 0) {
      return cookie.substring(nameEQ.length, cookie.length)
    }
  }
  return null
}

function deleteCookie(name) {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
}

function clearAllIntervals() {
  const highestIntervalId = setInterval(() => {}, 0); // Obtém o ID do maior setInterval ativo
  for (let i = 0; i <= highestIntervalId; i++) {
    clearInterval(i); // Limpa todos os intervalos
  }
}

export { setCookie, getCookie, deleteCookie, clearAllIntervals }