<template>
  <div v-if="visible" class="message-box">
    <div class="message-box-content">
      <!-- safe html -->
      <p v-html="message"></p>
      <div class="message-box-actions mt-5">
        <button class="btn button-link color-primary w-40 mx-3" @click="close">{{ buttonCloseText }}</button>
        <button class="btn btn-sm button-primary w-40" @click="confirm">{{ buttonConfirmText }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MessageBox",
  props: {
    message: {
      type: String,
      required: true
    },
    visible: {
      type: Boolean,
      default: false
    },
    buttonConfirmText: {
      type: String,
      default: "Confirmar"
    },
    buttonCloseText: {
      type: String,
      default: "Fechar"
    }
  },
  emits: ["close", "confirm"],
  methods: {
    close() {
      this.$emit("close");
    },
    confirm() {
      this.$emit("confirm");
    }
  }
};
</script>

<style scoped>
.message-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 40px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}
.message-box-content p {
  margin: 0 0 20px;
}
.message-box-actions {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: flex-end;
}

.btn-sm {
  border-radius: 30px;
  padding: 0.5rem 1rem;
}

.btn-sm:hover {
  color: white;
}

.color-primary:hover {
  color: #701E66;
}
</style>
