<template>
  <div class="d-flex flex-column align-items-center w-100 h-100" :class="classViewAdmin">
    <span class="fs-30 color-primary fw-bold mb-3">Painel administrativo</span>
    <input type="text"
           class="search-name-input"
           placeholder="Procure um cliente pelo @ do Instagram"
           v-model="name"
           v-on:keyup="searchAccount"/>
    <div class="d-flex flex-column align-items-center" v-if="notFound">
      <img src="@/assets/images/not-found.png" alt="Nenhum usuário encontrado" class="mt-4 icon-xl" />
      <span class="fs-15 fc-label fw-bold mt-2">Nenhum usuário encontrado</span>
    </div>

    <div v-if="account && !success" class="d-flex flex-column align-items-center w-content mt-3">
      <div class="d-flex flex-column align-items-center mb-3">
        <img :src="account.picture" alt="Foto de perfil do cliente" class="rounded-circle mt-4 border-green" width="100" height="100">
        <span class="fw-bold fs-15 mt-2">@{{ account.name }}</span>
        <span class="fw-bold label-email fs-12">{{ account.user.email }}</span>
      </div>

      <form class="w-form">
        <div class="d-flex flex-column mb-3 mt-3">
          <label class="fc-label fw-bold m-label">Expiração do plano</label>
          <input placeholder="Data de expiração do plano" type="date" v-model="account.user.plan_expiration_date" class="input-login mt-2" />
        </div>

        <span class="fc-label fw-bold m-label mt-4">Visualização do logotipo</span>
        <div class="d-flex justify-content-between mt-2">
          <div class="d-flex align-items-center justify-content-center">
            <input type="radio" id="solid" name="radio-group-logo_opacity" :value="100" v-model="account.logo_opacity">
            <label for="solid" class="fs-12 fc-label ms-1">Sólido</label>
          </div>
          <div class="d-flex align-items-center justify-content-center">
            <input type="radio" id="reels" name="radio-group-logo_opacity" :value="60" v-model="account.logo_opacity">
            <label for="reels" class="fs-12 fc-label ms-1">Com transparência</label>
          </div>
          <div class="d-flex align-items-center justify-content-center">
            <input type="radio" id="hide" name="radio-group-logo_opacity" :value="0" v-model="account.logo_opacity">
            <label for="hide" class="fs-12 fc-label ms-1">Oculto</label>
          </div>
        </div>

        <button type="button" @click="updateAdmin" class="button-save mt-5 w-100 d-flex align-items-center justify-content-center">
          <span class="fw-bold fs-15" v-if="!loading">
            Salvar
          </span>
          <div class="spinner-border spinner-border-sm text-light" role="status" v-else>
            <span class="visually-hidden"></span>
          </div>
        </button>
      </form>
    </div>

    <div v-if="success" class="d-flex flex-column align-items-center w-content mt-3">
      <img src="@/assets/images/user-updated.png" alt="Usuário atualizado com sucesso" class="mt-4 icon-xl" />
      <span class="fs-15 fc-label fw-bold mt-2">Usuário atualizado com sucesso</span>
      <button type="button" @click="reloadPage" class="d-flex align-items-center justify-content-center btn btn-link text-decoration-none">
        <span class="fw-bold fs-15">
          Voltar para o início
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import api from '@/services/api'

export default {
  name: 'AdminView',
  data() {
    return {
      submitted: false,
      loading: false,
      name: '',
      notFound: false,
      account: null,
      success: false
    }
  },
  created() {
    if (!this.currentUser) {
      this.$router.push('/sign-in')
    } else if (this.currentUser && this.isCustomer) {
      this.$router.push('/accounts')
    }
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    isCustomer() {
      return !this.currentUser.permission || this.currentUser.permission < 1
    },
    classViewAdmin() {
      return {
        'justify-content-center': !this.account,
        'mt-4': this.account
      }
    }
  },
  methods: {
    ...mapActions('auth', ['setCurrentUser']),
    async searchAccount(event) {
      this.notFound = false
      
      if (event.keyCode && event.keyCode !== 13) return

      this.account = null
      this.success = false

      try {
        this.submitted = true

        if (!this.name) return

        const params = {
          name: this.name
        }

        const response = await api.accounts.by_name(params)

        if (response.status === 200) {
          this.account = response.data
          this.account.user.plan_expiration_date = new Date(this.account.user.plan_expiration_date).toISOString().split('T')[0]
        } else {
          this.notFound = true
        }
      } catch (error) {
        this.notFound = true
        console.error('Não foi possível buscar o usuário: ', error)
      }
    },
    updateAdmin() {
      if (this.loading) return

      this.loading = true

      try {
        api.accounts.update_admin(this.account).then(response => {
          if (response.status === 200) {
            this.success = true
          }
        }).catch(error => {
          console.error('Não foi possível atualizar o usuário: ', error)
        })
      } finally {
        this.loading = false
      }
    },
    reloadPage() {
      this.$router.go()
    }
  }
}
</script>

<style scoped>
.search-name-input {
  border: 1px solid #701E66;
  border-radius: 30px;
  padding: 5px 20px;
  width: 30%;
  color: #9E9B9B;
  font-size: 12px;
  font-weight: bold;
}

.search-name-input::placeholder {
  font-weight: bold;
  color: #9E9B9B;
  font-size: 12px;
}

.border-green {
  border: 2px solid #00FF38;
}

.label-email {
  color: #9E9B9B;
}

.w-content {
  width: 100%;
}

.w-form {
  width: 20%;
  min-width: 360px;
}

.button-save {
  background-color: #701E66;
  color: white;
  border: none;
  border-radius: 30px;
  padding: 10px;
  height: 38px;
  cursor: pointer;
  font-weight: bold;
}
</style>