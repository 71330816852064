<template>
  <div class="background-primary align-content-center full-screen">
    <div class="full-screen d-flex flex-column align-items-center">
      <div class="d-flex flex-row align-items-center content-width justify-content-center position-relative">
        <button @click="goToSignIn" class="btn btn-link back-link" type="button">
          <img src="@/assets/images/arrow-left.png" alt="Voltar" />
        </button>
      </div>
      <div class="margin-top-background-login border-login full-screen content-width">
        <span class="title-auth">Cadastro</span>
        <form @submit.prevent="submitForm" class="p-4 d-flex flex-column">
          <input placeholder="E-mail" type="email" v-model="v$.userEmail.$model" class="input-login" />
          <span v-if="v$.userEmail.$errors.length && submitted" class="color-danger fs-12 mt-3">{{ v$.userEmail.$errors[0]?.$message }}</span>
          <input placeholder="Senha" type="password" v-model="v$.password.$model" class="input-login mt-3" />
          <span v-if="v$.password.$errors.length && submitted" class="color-danger fs-12 mt-3">{{ v$.password.$errors[0]?.$message }}</span>
          <input placeholder="Confirmar senha" type="password" v-model="v$.confirmPassword.$model" class="input-login mt-3" />
          <span v-if="v$.confirmPassword.$errors.length && submitted" class="color-danger fs-12 mt-3">{{ v$.confirmPassword.$errors[0]?.$message }}</span>
          <span v-if="error_message" class="color-danger fs-12 mt-3">{{ error_message }}</span>
          <button type="submit" class="primary-button mt-4">
            <span class="fw-bold fs-15" v-if="!loading">
              Criar conta
            </span>
            <div class="spinner-border spinner-border-sm text-light" role="status" v-else>
              <span class="visually-hidden"></span>
            </div>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { mapActions, mapState } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, email, helpers, sameAs, minLength } from '@vuelidate/validators'
import api from '@/services/api'
import { getCookie, clearAllIntervals } from '@/utils/cookies'

export default {
  name: 'SignInView',
  data() {
    return {
      submitted: false,
      error_message: '',
      loading: false
    }
  },
  created() {
    if (this.currentUser) {
      this.$router.push('/accounts')
    }
  },
  computed: {
    ...mapState('auth', ['currentUser'])
  },
  methods: {
    ...mapActions('auth', ['setCurrentUser']),
    async submitForm() {
      if (this.loading) return

      this.loading = true

      try {
        const authorization_code = getCookie('authorization_code')
        this.error_message = ''
        const isFormCorrect = await this.v$.$validate()
        if (!isFormCorrect) {
          this.submitted = true
          return
        }

        const params = {
          email: this.v$.userEmail.$model,
          password: this.v$.password.$model,
          authorization_code
        }

        const response = await api.auth.signUp(params)

        const { data } = response

        this.setCurrentUser(data)
        clearAllIntervals()
        api.auth.setVuex({ authorization_code, vuex: localStorage.getItem('vuex') })
        this.$router.push('/accounts')
      } catch (error) {
        console.error('Erro ao criar conta:', error)
        this.error_message = error.response.data.error[0]
      } finally {
        this.loading = false
      }
    },
    goToSignIn() {
      this.$router.push({
        path: '/sign-in',
        query: {
          authorization_code: getCookie('authorization_code')
        }
      })
    }
  },
  setup () {
    const userEmail = ref('')
    const password = ref('')
    const confirmPassword = ref('')

    const rules = {
      userEmail: {
        required: helpers.withMessage('Informe seu e-mail', required),
        email: helpers.withMessage('Informe um e-mail válido', email)
      },
      password: {
        required: helpers.withMessage('Informe sua senha', required),
        minLength: helpers.withMessage('Mínimo: 6 caracteres', minLength(6))
      },
      confirmPassword: {
        required: helpers.withMessage('Confirme sua senha', required),
        sameAsPassword: helpers.withMessage('As senhas precisam ser iguais', sameAs(password))
      }
    }

    const v$ = useVuelidate(rules, {
      userEmail,
      password,
      confirmPassword
    })

    return { v$ }
  }
}
</script>

<style scoped>
input::placeholder {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: #9E9B9B;
}
</style>