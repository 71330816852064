import axios from 'axios'
import { API_URL } from '@/config'

import admin from './api/admin'
import accounts from './api/accounts'
import auth from './api/auth'
import users from './api/users'

const api = axios.create({ baseURL: API_URL })

api.defaults.headers.common['Authorization'] = localStorage.getItem('jwt-token')
// api.defaults.headers.common['Cache-Control'] = 'no-cache, no-store, must-revalidate'
// api.defaults.headers.common['Pragma'] = 'no-cache'
// api.defaults.headers.common['Expires'] = '0'

api.interceptors.response.use(
  (response) => {
    if (response?.data?.token) {
      localStorage.setItem('jwt-token', response.data.token)
      api.defaults.headers.common['Authorization'] = response.data.token
    }

    return response
  },
  (error) => {
    const { url } = error.response.config
    const { status } = error.response

    if (status === 401 && url !== '/users/sign_in') {
      localStorage.removeItem('jwt-token')
      localStorage.removeItem('vuex')
      api.defaults.headers.common = {}
      window.location.href = '/sign-in'
    }
    return Promise.reject(error)
  }
)

export const onSignout = async () => {
  localStorage.removeItem('jwt-token')
  localStorage.removeItem('vuex')
  api.defaults.headers.common = {}
}

export default {
  admin: admin(api),
  accounts: accounts(api),
  auth: auth(api),
  users: users(api)
}
