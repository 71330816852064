<template>
  <div class="background-primary align-content-center full-screen">
    <div class="full-screen d-flex flex-column align-items-center">
      <div class="d-flex flex-column align-items-center justify-content-center logo-bg">
      </div>
        <div class="logo mt-5 mb-5 d-flex justify-content-center content-width">
          <img src="@/assets/images/logo-horizontal-white.png" alt="MeuStory.TV" />
        </div>
        <div class="d-flex flex-column border-login full-screen content-width">
          <button type="button" v-if="!showFormLogin" class="primary-button mt-4" @click="showLogin">
            <span class="fw-bold fs-15">
              Acesse sua conta
            </span>
          </button>
          <div v-if="showFormLogin">
            <span class="title-auth">Login</span>
            <form @submit.prevent="submitForm" class="p-4 d-flex flex-column">
              <input placeholder="E-mail" type="email" v-model="v$.userEmail.$model" class="input-login" />
              <span v-if="v$.userEmail.$errors.length && submitted" class="color-danger fs-12 mt-3">{{ v$.userEmail.$errors[0]?.$message }}</span>
              <input placeholder="Senha" type="password" v-model="v$.password.$model" class="input-login mt-3" />
              <span v-if="v$.password.$errors.length && submitted" class="color-danger fs-12 mt-3">{{ v$.password.$errors[0]?.$message }}</span>
              <span v-if="error_message && submitted" class="color-danger fs-12 mt-3">{{ error_message }}</span>
              <button type="button" @click="goToForgotPassword" class="btn btn-link mt-3 align-self-end link-forgot-password fw-bold">
                Esqueci minha senha
              </button>
              <button type="submit" class="primary-button mt-4">
                <span class="fw-bold fs-15">
                  Entrar
                </span>
              </button>
              <button type="button" class="primary-button-outlined mt-3" @click="goToSignUp">
              <span class="fw-bold fs-15">
                Criar uma conta
              </span>
            </button>
            </form>
          </div>
          <button type="button" class="primary-button-outlined mt-3" @click="goToSignUp" v-if="!showFormLogin">
            <span class="fw-bold fs-15">
              Criar uma conta
            </span>
          </button>

          <figure class="qrcode mt-5 d-flex flex-column" v-if="authorization_code">
            <span class="fw-bold fs-15 align-self-center fc-label">
              Ou acesse pelo QR Code
            </span>
            <vue-qrcode
              :value="externalLink"
              tag="svg"
              :options="{
                color: {
                  dark: '#902584'
                }
              }"
            ></vue-qrcode>
            <img class="qrcode__image" src="@/assets/images/logo-qrcode.png" alt="QR Code" />
          </figure>
        </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { mapActions, mapState } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators'
import api from '@/services/api'
import { getCookie, setCookie, clearAllIntervals } from '@/utils/cookies'

export default {
  name: 'SignInView',
  data() {
    return {
      submitted: false,
      error_message: '',
      showFormLogin: false,
      watchIntervalAuthorize: null,
      authorization_code: ''
    }
  },
  created() {
    if (this.currentUser) {
      this.$router.push({
        path: '/accounts',
        query: {
          authorization_code: this.$route.query.authorization_code
        }
      })
    }
    this.showFormLogin = this.$route.query.show_form || false
    clearAllIntervals()
    this.createAuthorization()
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    externalLink() {
      return `${process.env.VUE_APP_BASE_URL}/sign-in?authorization_code=${this.authorization_code}`
    }
  },
  methods: {
    ...mapActions('auth', ['setCurrentUser']),
    async submitForm() {
      try {
        const authorization_code = getCookie('authorization_code')
        this.submitted = true
        const isFormCorrect = await this.v$.$validate()
        if (!isFormCorrect) return
        const params = {
          email: this.v$.userEmail.$model,
          password: this.v$.password.$model,
          authorization_code
        }

        const response = await api.auth.signIn(params)

        const { data } = response

        this.setCurrentUser(data)
        clearAllIntervals()
        api.auth.setVuex({ authorization_code, vuex: localStorage.getItem('vuex') })
        this.$router.push('/accounts')
      } catch (error) {
        console.error('Erro ao fazer login:', error)
        this.error_message = error.response.data.error[0]
      }
    },
    showLogin() {
      this.showFormLogin = true
    },
    createAuthorization() {
      const authorization_code = this.$route.query.authorization_code || getCookie('authorization_code')

      if (authorization_code) {
        this.authorization_code = authorization_code
        setCookie('authorization_code', authorization_code, 7)
        this.watchAuthorization(authorization_code)
        return
      }

      api.auth.createAuthorization().then(response => {
        const { authorization_code } = response.data
        this.authorization_code = authorization_code
        setCookie('authorization_code', authorization_code, 7)
        this.watchAuthorization(authorization_code)
      }).catch(error => {
        console.error('Erro ao criar request login:', error)
      })
    },
    watchAuthorization(authorization_code) {
      setInterval(() => {
        api.auth.verifyAuthorization(authorization_code).then(response => {
          if (response.data.token && response.data.vuex) {
            localStorage.setItem('jwt-token', response.data.token)
            localStorage.setItem('vuex', response.data.vuex)
            clearAllIntervals()
            window.location.reload()
          }
        }).catch(error => {
          console.error('Erro ao verificar request login:', error)
        })
      }, 3000)
    },
    goToSignUp() {
      this.$router.push({ path: '/sign-up', query: { authorization_code: getCookie('authorization_code') }})
    },
    goToForgotPassword() {
      this.$router.push({ path: '/forgot-password', query: { authorization_code: getCookie('authorization_code') }})
    }
  },
  setup () {
    const userEmail = ref('')
    const password = ref('')

    const rules = {
      userEmail: {
        required: helpers.withMessage('Informe seu e-mail', required),
        email: helpers.withMessage('Informe um e-mail válido', email)
      },
      password: {
        required: helpers.withMessage('Informe sua senha', required)
      }
    }

    const v$ = useVuelidate(rules, {
      userEmail,
      password
    })

    return { v$ }
  }
}
</script>

<style scoped>
.logo {
  position: relative;
}

@media (min-width: 768px) {
  /* .logo-bg {
    position: absolute;
    background-image: url('@/assets/images/logo-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 815px;
    margin-top: 90px;
  } */
}

.qrcode {
  /* display: inline-block; */
  /* font-size: 0; */
  /* margin-bottom: 0; */
  align-self: center;
  width: 200px;
  position: relative;
}

.qrcode__image {
  background-color: #fff;
  border: 0.25rem solid #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  height: 15%;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 15%;
}

</style>