import { createRouter, createWebHistory } from 'vue-router'

import InitialView from '@/views/InitialView'
import SignInView from '@/views/auth/SignInView'
import SignUpView from '@/views/auth/SignUpView'
import ForgotPasswordView from '@/views/auth/ForgotPasswordView.vue'
import ChangePasswordView from '@/views/auth/ChangePasswordView.vue'

// Accounts
import AccountsView from '@/views/accounts/AccountsView.vue'
import AccountShowView from '@/views/accounts/AccountShowView.vue'
import AccountConfig from '@/views/accounts/AccountConfig.vue'

// Privacy Policy
import PrivacyPolicyView from '@/views/PrivacyPolicyView.vue'
import TermsOfServiceView from '@/views/TermsOfServiceView.vue'

import AdminView from '@/views/admin/AdminView.vue'

const routes = [
  {
    path: '/',
    name: 'initial',
    component: InitialView
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: SignInView,
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: SignUpView
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPasswordView
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: ChangePasswordView
  },
  {
    path: '/accounts',
    name: 'accounts',
    component: AccountsView
  },
  {
    path: '/accounts/:id/config',
    name: 'accounts-config',
    component: AccountConfig
  },
  {
    path: '/accounts/:id/show',
    name: 'accounts-show',
    component: AccountShowView
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicyView
  },
  {
    path: '/terms-of-service',
    name: 'terms-of-service',
    component: TermsOfServiceView
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router