export default (api) => ({
  signIn: (data) => {
    return api.post(
      `/users/sign_in`,
      {
        user: {
          email: data.email,
          password: data.password
        },
        authorization_code: data.authorization_code
      }
    )
  },
  signUp: (params) => {
    return api.post(
      `/users`,
      {
        user: params,
        authorization_code: params.authorization_code
      }
    )
  },
  forgotPassword: (params) => {
    return api.post(
      `/users/forgot_password`,
      params
    )
  },
  updatePassword: (params) => {
    return api.put(
      `/users/password`,
      params
    )
  },
  createAuthorization: () => {
    return api.post(
      `/authorizations`
    )
  },
  deleteAuthorization: (authorization_code) => {
    return api.delete(
      `/authorizations/${authorization_code}`
    )
  },
  verifyAuthorization: (authorization_code) => {
    return api.get(
      `/authorizations/verify?authorization_code=${authorization_code}`
    )
  },
  setVuex: (data) => {
    return api.post(
      `/authorizations/set_vuex`,
      data
    )
  },
})