<template>
  <div class="background-primary align-content-center full-screen">
    <div class="full-screen d-flex flex-column align-items-center">
      <div class="d-flex flex-row align-items-center content-width justify-content-center position-relative">
        <router-link to="/sign-in" class="back-link">
          <img src="@/assets/images/arrow-left.png" alt="Voltar" />
        </router-link>
      </div>
      <div class="margin-top-background-login border-login full-screen content-width">
        <span class="title-auth">Alterar senha</span>
        <form @submit.prevent="submitForm" class="p-4 d-flex flex-column">
          <input placeholder="Nova senha" type="password" v-model="v$.password.$model" class="input-login" />
          <span v-if="v$.password.$errors.length && submitted" class="color-danger fs-12 mt-3">{{ v$.password.$errors[0]?.$message }}</span>
          <input placeholder="Confirmar senha" type="password" v-model="v$.password_confirmation.$model" class="input-login mt-3" />
          <span v-if="v$.password_confirmation.$errors.length && submitted" class="color-danger fs-12 mt-3">{{ v$.password_confirmation.$errors[0]?.$message }}</span>
          <button type="submit" class="primary-button mt-4">
            <span class="fw-bold fs-15" v-if="!loading">
              Alterar senha
            </span>
            <div class="spinner-border spinner-border-sm text-light" role="status" v-else>
              <span class="visually-hidden"></span>
            </div>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { mapActions, mapState } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, sameAs, minLength } from '@vuelidate/validators'
import api from '@/services/api'

export default {
  name: 'ChangePasswordView',
  data() {
    return {
      submitted: false,
      loading: false
    }
  },
  created() {
    if (this.currentUser) {
      this.$router.push('/accounts')
    }
  },
  computed: {
    ...mapState('auth', ['currentUser'])
  },
  methods: {
    ...mapActions('auth', ['setCurrentUser']),
    async submitForm() {
      if (this.loading) return

      this.loading = true

      try {
        this.submitted = true
        const isFormCorrect = await this.v$.$validate()
        if (!isFormCorrect) return

        const params = {
          password: this.v$.password.$model,
          password_confirmation: this.v$.password_confirmation.$model,
          reset_password_token: this.$route.query.token
        }

        const response = await api.auth.updatePassword(params)

        if (response.status === 200) {
          this.$router.push('/sign-in')
        }
      } catch (error) {
        console.error('Erro ao alterar senha:', error)
      } finally {
        this.loading = false
      }
    }
  },
  setup () {
    const password = ref('')
    const password_confirmation = ref('')

    const rules = {
      password: {
        required: helpers.withMessage('Informe sua senha', required),
        minLength: helpers.withMessage('A senha deve ter no mínimo 6 caracteres', minLength(6))
      },
      password_confirmation: {
        required: helpers.withMessage('Confirme sua senha', required),
        sameAsPassword: helpers.withMessage('As senhas precisam ser iguais', sameAs(password))
      }
    }

    const v$ = useVuelidate(rules, {
      password,
      password_confirmation
    })

    return { v$ }
  }
}
</script>